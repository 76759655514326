//Header Page css
.headerPage{height: 95px;}
.headerPage a{color: rgb(33,37,41); font-weight:bold; text-decoration: none;}
.headerPage .borderB {
    background: linear-gradient(#fff, #fff), linear-gradient(to right, #244cfd,#15e4fd);
    background-origin: padding-box, border-box;
    background-repeat: no-repeat;
    border-bottom: 3px solid transparent;
  }
.headerPage .dropdown-menu {right:0;}
// login Page css
.loginPage .loginOuter{
  position: fixed;
  width: 100%;
  height: 100vh;
  top:0;
  left:0;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.8);
  z-index: 1040;
}  

//Banner page css
.banner{
    height: 400px;
    background-image: url('./../assets/img/banner.png');
    background-position: center;
    background-size: cover;
}

//timeSlotPicker page
.timeSlotPicker .timeSlot{
  display: inline-block;
  padding: 5px;
  border-radius: 2px;
  background-color: #e9e9e9;
  color: rgb(33,37,41);
  margin: 5px 5px;
  width: 90px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.timeSlotPicker .timeSlot.active{background-color: #244cfd; color:#fff; cursor: default;}

//Department Box page
.departmentBoxHover{
  background-image: linear-gradient(to right, #fff,#15e4fd);
}
// Login CSS
.loginpage{background: linear-gradient(to right, #244cfd,#15e4fd);}
.loginpage .loginImg{
  background-image: url('./../assets/img/login.jpg');
  background-position: center;
  background-size: cover;
}
//Footer page css
.footerPage .socialIcon{display: inline-block; font-size: 35px; }
.footerPage .socialIcon a {
  color: #ffffff;
}

//timeSlotPicker page
.timeSlotPicker .timeSlot{
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #244cfd;
  background-color: #ffffff;
  color: rgb(33,37,41);
  margin: 5px 5px;
  width: 90px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.timeSlotPicker .timeSlot.active{background-color: #244cfd; color:#fff; cursor: default;}
.timeSlotPicker .timeSlot#booked{background-color: #d3d3d3; cursor: default;}

.loadPage{
  position: fixed;
  top: 0px;
  left: 0px;
  width:100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.8);
}